import {dom} from 'core-utils';
export default class ModelBand extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void}
	 */
	connectedCallback() {
		this._initializeModule();
	}

	/**
	 * disconnectedCallback
	 * @returns {void}
	 */
	disconnectedCallback() {
		window.removeEventListener('resize', dom.throttle(this._resizeModule.bind(this), 50));
		window.removeEventListener('scroll', dom.throttle(this._scrollHandler.bind(this), 100));
	}

	/**
	 * _initializeModule
	 * @returns {void}
	 */
	_initializeModule() {
		this._oDefaults = {
			selectorContainerWrap: '.nm-model-band-container',
			selectorContainerItem: '.nm-model-band-container-item',
			selectorEecContainer: '.nm-model-band-eec-explanation',
			selectorStripeContainer: '.nm-model-band-stripe-container',
			selectorStripe: '.nm-model-band-stripe',
			selectorStripeItem: '.nm-model-band-stripe-item',
			classContainerIsOpen: 'nm-j-model-band-container-is-open',
			classContainerSticky: 'nm-j-model-band-container-sticky',
			classContainerItemVisible: 'nm-model-band-container-item-visible',
			classHidePrevArrow: 'nm-j-model-band-hide-prev-arrow',
			classHideNextArrow: 'nm-j-model-band-hide-next-arrow'
		};

		this._scrollDuration = 500;
		this.stripes = this.querySelectorAll(this._oDefaults.selectorStripe);
		this.stripeItems = this.querySelectorAll(this._oDefaults.selectorStripeItem);
		this.navigationItems = this.querySelectorAll('.nm-model-band-tab-navigation li');
		this.prevButtons = this.querySelectorAll('.nm-model-band-stripe-prev');
		this.nextButtons = this.querySelectorAll('.nm-model-band-stripe-next');
		this.defaultInputs = this.querySelectorAll('.nm-model-band-default-input');

		this._reset();
		this._addEvents();
		this._resizeModule();
		this._setContainerItemHeight();
		this._handleNavigationArrows();
		this._addAriaTags();
	}

	/**
	 * _reset - private funtion resetting Everything
	 * @returns {void}
	 */
	_reset() {
		this._resetInputs();
		this._resetItems();
	}

	/**
	 * _resetInputs - private function to reset input by activating the invisible default input
	 * @returns {void}
	 */
	_resetInputs() {
		const inputs = this.querySelectorAll('.nm-model-band-input');
		[...inputs].forEach((item) => {
			item.checked = false;
		});
		[...this.defaultInputs].forEach((item) => {
			item.checked = true;
		});
	}

	/**
	 * _resetItems - private function to reset items
	 * @returns {void}
	 */
	_resetItems() {
		const container = this.querySelector(this._oDefaults.selectorContainerWrap);
		const containerItems = this.querySelectorAll(this._oDefaults.selectorContainerItem);
		container.classList.remove(this._oDefaults.classContainerIsOpen);
		container.style.height = '0px';
		[...containerItems].forEach((item) => {
			item.style.height = '0px';
			item.classList.remove(this._oDefaults.classContainerItemVisible);
		});
	}

	/**
	 * _addEvents - private function adding events
	 * @returns {void}
	 */
	_addEvents() {
		window.addEventListener('resize', dom.throttle(this._resizeModule.bind(this), 50));
		window.addEventListener('scroll', dom.throttle(this._scrollHandler.bind(this), 100));
		[...this.stripes].forEach((element) => {
			element.addEventListener('scroll', this._handleNavigationArrows.bind(this));
		});

		[...this.navigationItems].forEach((element) => {
			element.addEventListener('click', this._handleNavItemClick.bind(this));
		});
		[...this.stripeItems].forEach((element) => {
			element.addEventListener('click', this._handleStripeItemClick.bind(element, this));
		});
		[...this.defaultInputs].forEach((element) => {
			element.addEventListener('click', this._resetItems.bind(this));
		});

		[...this.prevButtons].forEach((element) => {
			element.addEventListener('click', this._handlePrevNextClick.bind(element, this));
		});
		[...this.nextButtons].forEach((element) => {
			element.addEventListener('click', this._handlePrevNextClick.bind(element, this));
		});
	}

	/**
	 * _resizeModule - private function for resizing elements in module
	 * @returns {void}
	 */
	_resizeModule() {
		const stripeContainer = this.querySelector(this._oDefaults.selectorStripeContainer);
		const stripe = this._getVisibleStripe();
		stripeContainer.classList.remove('nm-model-band-stripe-container-hidden');
		if (!!stripe) {
			this._setContainerItemHeight();
			this._handleNavigationArrows();
		}
	}

	/**
	 * _setContainerItemHeight - private function setting data attributes of current height
	 * @returns {void}
	 */
	_setContainerItemHeight() {
		const modelContainerItems = this.querySelectorAll('.nm-model-band-container-models ' + this._oDefaults.selectorContainerItem);
		const modelStripeItems = this.querySelectorAll('.nm-model-band-stripe-models-wrap ' + this._oDefaults.selectorStripeItem);

		const typeContainerItems = this.querySelectorAll('.nm-model-band-container-types ' + this._oDefaults.selectorContainerItem);
		const typeStripeItems = this.querySelectorAll('.nm-model-band-stripe-types-wrap ' + this._oDefaults.selectorStripeItem);

		[...modelContainerItems].forEach((item, i) => {
			let height = this._getItemHeight(item);
			modelStripeItems[i].setAttribute('data-height', height);
			item.setAttribute('data-height', height);
		});

		[...typeContainerItems].forEach((item, i) => {
			let height = this._getItemHeight(item);
			typeStripeItems[i].setAttribute('data-height', height);
			item.setAttribute('data-height', height);
		});
	}

	/**
	 * _handleNavigationArrows - private function for hiding navigation arrows when neccessary
	 * @returns {void}
	 */
	_handleNavigationArrows() {
		const stripes = this.stripes;

		[...stripes].forEach((stripe) => {
			let stripeWrap = dom.closest(stripe, '.nm-model-band-stripe-wrap');

			let defaultPadding = 10;
			let stripeWidth = stripe.querySelector('ul').getBoundingClientRect().width - stripe.getBoundingClientRect().width;
			if ((stripe.scrollLeft <= defaultPadding)) {
				stripeWrap.classList.add(this._oDefaults.classHidePrevArrow);
				stripeWrap.classList.remove(this._oDefaults.classHideNextArrow);
			}
			else if (stripe.scrollLeft < (stripeWidth - defaultPadding)) {
				stripeWrap.classList.remove(this._oDefaults.classHideNextArrow);
				stripeWrap.classList.remove(this._oDefaults.classHidePrevArrow);
			}
			else {
				stripeWrap.classList.add(this._oDefaults.classHideNextArrow);
				stripeWrap.classList.remove(this._oDefaults.classHidePrevArrow);
			}
		});
	}

	_addAriaTags(){
		this.stripes.forEach(stripe => stripe.setAttribute('aria-expanded', false));
		if (this._getVisibleStripe()){
			this._getVisibleStripe().setAttribute('aria-expanded', true);
		}

		const navItems = this.navigationItems;
		navItems.forEach(nav => {
			nav.setAttribute('aria-selected', false);
			const label = nav.querySelector('label');
			const forAttrVal = label.getAttribute('for');
			const inputElementForLabel = document.querySelector(`#${forAttrVal}`);
			const isInputChecked = inputElementForLabel.checked;
			if (isInputChecked) {
				label.parentElement.setAttribute('aria-selected', true);
			}
		});
	}

	/**
	 * _scrollHandler - private function for scroll event handling on window
	 * @returns {void}
	 */
	_scrollHandler() {
		const container = this.querySelector(this._oDefaults.selectorContainerWrap);
		const containerStartPos = dom.getElementsAbsoluteTopPosition(container);
		const eecContainer = container.querySelector(this._oDefaults.selectorEecContainer);
		const eecHeight = eecContainer ? eecContainer.scrollHeight : 0;
		const currentItem = this.querySelector('.' + this._oDefaults.classContainerItemVisible);
		let headlineHeight = 0;

		if (currentItem) {
			headlineHeight = currentItem.querySelector('.nm-model-band-container-headline').scrollHeight;
			const containerEndPos = containerStartPos + container.clientHeight - eecHeight - headlineHeight;

			if (container.classList.contains(this._oDefaults.classContainerIsOpen) && window.pageYOffset > containerStartPos && window.pageYOffset < containerEndPos) {
				container.classList.add(this._oDefaults.classContainerSticky);
				currentItem.style.paddingTop = headlineHeight + 'px';
			}
			else {
				container.classList.remove(this._oDefaults.classContainerSticky);
				currentItem.style.paddingTop = '0px';
			}
		}
	}

	/**
	 * _handleNavItemClick - private function click event handling on Navigation Elements
	 * @returns {void}
	 */
	_handleNavItemClick() {
		const stripe = this._getVisibleStripe();
		stripe.scrollLeft = 0;

		this._reset();
		let modelBand = this;
		setTimeout(function () {
			modelBand._setContainerItemHeight();
			modelBand._handleNavigationArrows();
			modelBand._addAriaTags();
		}, 100);
	}

	_showOrHideStripeItems(carlineImageArray, currentInputType, currentIndex, isShown) {
		carlineImageArray.forEach(carlineImage => {
			const parentLiElement = carlineImage.closest('.nm-model-band-stripe-item');
			if (!parentLiElement.classList.contains(`nm-model-band-stripe-${currentInputType}-item${currentIndex}`)) {
				carlineImage.style.display = isShown ? 'block' : 'none';
			}
			else {
				carlineImage.style.display = isShown ? 'none' : 'block';
			}
		});
	}

	_updateActiveCarView(modelBand_, currentItem, currentInputType) {
		if (modelBand_ && currentItem.dataset.loadingImage === undefined) {
			const carlineImageWithFrontView = currentItem.querySelector('.nm-model-band-stripe-img-active');
			const allDefaultCarlineImages = modelBand_.querySelectorAll('.nm-model-band-stripe-img');
			const allFrontLineImages = modelBand_.querySelectorAll('.nm-model-band-stripe-img-active');
			const randomId = modelBand_.dataset.id;
			const currentIndex = currentItem.dataset.index;
			if (carlineImageWithFrontView) {
				const radioInput = modelBand_.querySelector(`input.nm-model-band-${currentInputType}-input.nm-model-band-${randomId}-${currentInputType}-${currentIndex}`);
				if (radioInput && radioInput.checked) {
					currentItem.dataset.loadingImage = '1';
					carlineImageWithFrontView.addEventListener('load', () => {
						delete currentItem.dataset.loadingImage;
						this._showOrHideStripeItems(allDefaultCarlineImages, currentInputType, currentIndex, true);
						this._showOrHideStripeItems(allFrontLineImages, currentInputType, currentIndex, false);
					}, {once: true});

					carlineImageWithFrontView.setAttribute('src', carlineImageWithFrontView.getAttribute('data-src'));
					carlineImageWithFrontView.setAttribute('loading', 'eager');
				}
				else {
					allFrontLineImages.forEach(carlineImage => {
						carlineImage.style.display = 'none';
					});
					allDefaultCarlineImages.forEach(carlineImage => {
						carlineImage.style.display = 'block';
					});
				}
			}
		}
	}

	/**
	 * _handleStripeItemClick - private function for click event handling on stripe items
	 * @param {HTMLElement} modelBand_ - Instance of this Module
	 * @returns {void}
	 */
	_handleStripeItemClick(modelBand_) {
		const currentItem = this;
		let currentInputType = modelBand_._getCurrentInputType(currentItem);
		setTimeout(function () {
			// update the active car view.
			modelBand_._updateActiveCarView(modelBand_, currentItem, currentInputType);
		}, 0);

		setTimeout(function () {
			let currentInput = modelBand_.querySelector('.nm-model-band-' + currentInputType + '-input:checked');
			if (currentInput) {
				modelBand_._loadDynamicModelData(modelBand_, currentItem, currentInputType)
					.then(function (content) {
						modelBand_._replaceDynamicContent(modelBand_, currentItem, content);
					})
					.catch(function (error) {
						console.error('Error while reading layer template', error);
					});
			}
			else {
				const container = modelBand_.querySelector(modelBand_._oDefaults.selectorContainerWrap);
				const containerItems = (container.querySelectorAll('.nm-model-band-container > ul')[modelBand_._getVisibleStripeIndex()]).querySelectorAll(modelBand_._oDefaults.selectorContainerItem);

				modelBand_._resetContainerHeight(modelBand_, container, containerItems);
			}
		}, 0);
	}

	_replaceDynamicContent(modelBand_, currentItem, newContent) {
		const that = modelBand_;
		const currentInputType = that._getCurrentInputType(currentItem);
		// replace the content now
		const container = that.querySelector(that._oDefaults.selectorContainerWrap);
		const containerItems = (container.querySelectorAll('.nm-model-band-container > ul')[that._getVisibleStripeIndex()]).querySelectorAll(modelBand_._oDefaults.selectorContainerItem);

		let currentInput = modelBand_.querySelector('.nm-model-band-' + currentInputType + '-input:checked');
		const currentIndex = parseInt(currentInput.dataset.index, 10);

		const containerContent = container.querySelector(`ul.nm-model-band-container-${currentInputType}`);
		if (containerContent) {
			that._resetContainerHeight(modelBand_, container, containerItems);
			containerContent.innerHTML = newContent;

			that._fixCloseButton(modelBand_, container, currentItem);

			that._fixHeight(modelBand_, container, currentItem, currentIndex);
		}
	}

	_fixHeight(modelBand_, container, currentItem, currentIndex) {
		const that = modelBand_;
		const currentInputType = that._getCurrentInputType(currentItem);

		// set container height and open view
		const containerContent = container.querySelector(`ul.nm-model-band-container-${currentInputType}`);
		container.classList.add(modelBand_._oDefaults.classContainerIsOpen);
		const childElement = containerContent.querySelector('.nm-model-band-container-item');
		that._setCurrentContainerItemHeight(childElement);

		const newHeight = that._getItemHeight(childElement);
		container.style.height = newHeight + 'px';

		that._handleStripeAnimation(currentItem, currentIndex);
		dom.scrollTo(dom.getElementsAbsoluteTopPosition(modelBand_), 200);
	}

	_fixCloseButton(modelBand_, container, currentItem) {
		const that = modelBand_;
		const currentInputType = that._getCurrentInputType(currentItem);

		// attach for-attribute to perfom a checkbox change on the label click
		const containerContent = container.querySelector(`ul.nm-model-band-container-${currentInputType}`);
		const closeButton = containerContent.querySelector('.nm-model-band-container-close-button');
		const defaultRadioButton = modelBand_.querySelector(`.nm-model-band-default-input.nm-model-band-${currentInputType}-default`);
		if (closeButton && defaultRadioButton) {
			closeButton.setAttribute('for', defaultRadioButton.id);
			closeButton.addEventListener('click', () => {
				setTimeout(() => {
					that._updateActiveCarView(modelBand_, currentItem, currentInputType);
				}, 0);
			});
		}
	}

	_resetContainerHeight(modelBand_, container, containerItems) {
		if (container.classList.contains(modelBand_._oDefaults.classContainerIsOpen)) {
			[...containerItems].forEach((item) => {
				item.style.height = 0;
				item.classList.remove(modelBand_._oDefaults.classContainerItemVisible);
			});
		}
	}

	_loadDynamicModelData(modelBand_, currentItem, currentInputType) {
		return new Promise((resolve, reject) => {
			const indexExtension = window.location.pathname.lastIndexOf('.');
			const path = window.location.pathname.substring(0, indexExtension);
			const currentCategory = currentItem.dataset.category;
			const referencedResourcePath = modelBand_.dataset.resourcepath;
			const targetUrl = path + '.model_band_dynamic.' + (currentInputType === 'models' ? 'carlinegroup' : 'bodytype') + '.' + currentCategory + '.html?resourcePath=' + encodeURIComponent(referencedResourcePath);

			fetch(targetUrl, {
				headers: {
					Accept: 'text/plain'
				},
				method: 'GET'
			})
				.then(function (response) {
					if (response.ok) {
						return response.text();
					}
					else {
						throw new Error(response.error);
					}
				})
				.then(function (content) {
					resolve(content);
				})
				.catch(function (error) {
					console.error('Error while loading dynamic model band data', error);
					reject(error);
				});
		});
	}

	/**
	 * _handleStripeAnimation - private function animate the current Stripe to the right position
	 * @param {HTMLElement} item_ - current active Stripe Item
	 * @param {number} index_ - index of current Stripe Item
	 * @returns {void}
	 */
	_handleStripeAnimation(item_, index_) {
		const currentStripe = dom.closest(item_, this._oDefaults.selectorStripe);
		const stripeContainer = dom.closest(item_, this._oDefaults.selectorStripeContainer);
		const containerWidth = stripeContainer.clientWidth;
		const itemWidth = item_.clientWidth;
		const itemPosX = (index_ * itemWidth) - (containerWidth / 2) + (itemWidth / 2);
		const scrollDuration = this._calculateScrollDuration(containerWidth, currentStripe.scrollLeft, itemPosX);
		dom.animateElementX(itemPosX, currentStripe, scrollDuration);
	}

	/**
	 * _setCurrentContainerItemHeight - sets Height of Current Item
	 * @param {HTMLElement} item_ - current active Stripe Item
	 * @returns {void}
	 */
	_setCurrentContainerItemHeight(item_) {
		item_.style.height = 'auto';
		item_.classList.add(this._oDefaults.classContainerItemVisible);
	}

	/**
	 * _handlePrevNextClick - private function for click event handling on prev an next Buttons
	 * @param {HTMLElement} modelBand_ - Instance of this Module
	 * @returns {void}
	 */
	_handlePrevNextClick(modelBand_) {
		const currentButton = this;
		let direction = 'next';
		if (currentButton.classList.contains('nm-model-band-stripe-prev')) {
			direction = 'prev';
		}
		modelBand_._translateStripe(modelBand_, direction);
	}

	/**
	 * _translateStripe - private function for translating the stripe
	 * @param {HTMLElement} modelBand_ - Instance of this Module
	 * @param {string} direction_ - 'next' or 'previous'
	 * @returns {void}
	 */
	_translateStripe(modelBand_, direction_) {
		const stripeContainer = modelBand_.querySelector(modelBand_._oDefaults.selectorStripeContainer);
		const stripe = modelBand_._getVisibleStripe();
		const stripeList = stripe.querySelector('.nm-model-band-stripe > ul');
		if (stripeList.clientWidth > stripeContainer.clientWidth) {
			const newTranslate = modelBand_._calculateNewScrollPosition(stripeContainer.clientWidth, stripe.scrollLeft, direction_, stripe);
			const scrollDuration = modelBand_._calculateScrollDuration(stripeContainer.clientWidth, stripe.scrollLeft, newTranslate);
			dom.animateElementX(newTranslate, stripe, scrollDuration);
		}
	}

	/**
	 * _calculateScrollDuration -  private function calculating the duration for the scrolling
	 * @param {number} stripeContainerWidth_ - the width of the container of the stripes
	 * @param {number} currentPos_ - the left scroll position of the stripe
	 * @param {number} newTranslate_ - the new scroll position
	 * @returns {number} the duration of the scrollings
	 */
	_calculateScrollDuration(stripeContainerWidth_, currentPos_, newTranslate_) {
		return this._scrollDuration * (Math.abs(currentPos_ - newTranslate_) / stripeContainerWidth_);
	}

	/**
	 * _calculateNewScrollPosition -  private function calculating the new scroll position
	 * @param {number} containerWidth_ - Width of Container
	 * @param {number} currentPos_ - the left scroll position of the stripe
	 * @param {string} direction_ - 'next' or 'prev'
	 * @param {HTMLElement} stripe_ - stripe that is scrolled
	 * @returns {number} the new scroll position
	 */
	_calculateNewScrollPosition(containerWidth_, currentPos_, direction_, stripe_) {
		const visibleItems = this._getVisibleItems(stripe_);
		let newTranslate;
		let newIndex;

		if (direction_ === 'next') {
			newIndex = this._getNewIndex(visibleItems[visibleItems.length - 1]);
			newTranslate = this._getItemWidth(visibleItems[visibleItems.length - 1]) * newIndex;
		}
		else {
			newTranslate = currentPos_ - (containerWidth_ - this._getRightBound(visibleItems[0]));
		}
		return newTranslate;
	}

	/**
	 * _getItemHeight - private function gettinh height of current item
	 * @param {HTMLElement} item_ - stripe item
	 * @returns {number} height - height of element
	 */
	_getItemHeight(item_) {
		const eecContainer = this.querySelector(this._oDefaults.selectorEecContainer);
		const eecContainerHeight = eecContainer ? eecContainer.scrollHeight : 0;
		const itemInner = item_.querySelector('.nm-model-band-container-item-inner');
		return itemInner.scrollHeight + eecContainerHeight;
	}

	/**
	 * _getVisibleStripe - private function for getting the visible stripe
	 * @returns {HTMLElement} HTML Element
	 */
	_getVisibleStripe() {
		const stripes = this.querySelectorAll(this._oDefaults.selectorStripe);
		let visibleStripe = null;
		[...stripes].forEach((stripe) => {
			var isVisible = window.getComputedStyle(stripe.parentNode, null).getPropertyValue('display');
			if (isVisible === 'block') {
				visibleStripe = stripe;
			}
		});
		return visibleStripe;
	}

	/**
	 * _getVisibleStripeIndex - private function for getting the visible stripe index
	 * @returns {numer} Index
	 */
	_getVisibleStripeIndex() {
		const stripes = this.querySelectorAll(this._oDefaults.selectorStripe);
		let visibleIndex = null;
		[...stripes].forEach((stripe, index) => {
			var isVisible = window.getComputedStyle(stripe.parentNode, null).getPropertyValue('display');
			if (isVisible === 'block') {
				visibleIndex = index;
			}
		});
		return visibleIndex;
	}

	/**
	 * _getCurrentInputType - private function getting the Type of Input
	 * @param {HTMLElement} item_ - stripe item
	 * @returns {string} return "types" or "models"
	*/
	_getCurrentInputType(item_) {
		const classArray = item_.parentNode.classList[0].split('-');
		return classArray[classArray.length - 1];
	}

	/**
	 * _getVisibleItems -  get visible items
	 * @param  {HTMLElement} stripe_ stripe
	 * @return {Array} returns array with all visible items
	 */
	_getVisibleItems(stripe_) {
		const items = stripe_.querySelectorAll(this._oDefaults.selectorStripeItem);
		let visibleItems = [];
		[...items].forEach((item) => {
			if (dom.isVisible(item, stripe_, false)) {
				visibleItems.push(item);
			}
		});
		return visibleItems;
	}

	/**
	 * _getNewIndex - gets item index from data attribute
	 * @param  {HTMLElement} item slider item
	 * @return {number} returns item index
	 */
	_getNewIndex(item) {
		return item.getAttribute('data-index') || 0;
	}

	/**
	 * _getItemWidth - get client width of item
	 * @param  {HTMLElement} item slider item
	 * @return {number} return client width of item
	 */
	_getItemWidth(item) {
		return item.clientWidth || 0;
	}

	/**
	 * _getRightBound
	 * @param  {HTMLElement} item slider item
	 * @return {number} returns right bound of item
	 */
	_getRightBound(item) {
		return item.getBoundingClientRect().right || 0;
	}

}
if (window.customElements.get('audi-model-band') === undefined) {
	window.customElements.define('audi-model-band', ModelBand);
}
